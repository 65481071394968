// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import './flex';
@import './responsive';
@import './mat-overrides';
@import './font';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$accent: #57647b;

$mat-primary: (
  50: #e8f4fa,
  100: #c5e5f3,
  200: #9ed3ec,
  300: #77c1e4,
  400: #4d5888,
  500: #2B266E,
  600: #000531,
  700: #2f96ce,
  800: #278cc8,
  900: #1a7cbf,
  A100: #f3faff,
  A200: #c0e4ff,
  A400: #8dcdff,
  A700: #74c2ff,
  contrast: (
    50: #{$accent},
    100: #{$accent},
    200: #{$accent},
    300: #{$accent},
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #{$accent},
    A200: #{$accent},
    A400: #{$accent},
    A700: #{$accent},
  ),
);

$mat-accent: (
  50: #ebecef,
  100: #cdd1d7,
  200: #abb2bd,
  300: #8993a3,
  400: #707b8f,
  500: #57647b,
  600: #4f5c73,
  700: #465268,
  800: #3c485e,
  900: #2c364b,
  A100: #97baff,
  A200: #6498ff,
  A400: #3176ff,
  A700: #1865ff,
  contrast: (
    50: #{$accent},
    100: #{$accent},
    200: #{$accent},
    300: #{$accent},
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #{$accent},
    A200: #{$accent},
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-warn: (
  50: #fae6ec,
  100: #f3c1cf,
  200: #ec98af,
  300: #e46f8e,
  400: #de5076,
  500: #d8315e,
  600: #d42c56,
  700: #ce254c,
  800: #c81f42,
  900: #bf1331,
  A100: #ffeff1,
  A200: #ffbcc6,
  A400: #ff899a,
  A700: #ff6f84,
  contrast: (
    50: #{$accent},
    100: #{$accent},
    200: #{$accent},
    300: #{$accent},
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #{$accent},
    A200: #{$accent},
    A400: #{$accent},
    A700: #{$accent},
  ),
);

$levels: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700;

body {
  @each $level in $levels {
    --mat-primary-#{$level}: #{map-get($mat-primary, $level)};
    --mat-primary-#{$level}-contrast: #{map-get(map-get($mat-primary, contrast), $level)};
  }
  @each $level in $levels {
    --mat-accent-#{$level}: #{map-get($mat-accent, $level)};
    --mat-accent-#{$level}-contrast: #{map-get(map-get($mat-accent, contrast), $level)};
  }
  @each $level in $levels {
    --mat-warn-#{$level}: #{map-get($mat-warn, $level)};
    --mat-warn-#{$level}-contrast: #{map-get(map-get($mat-warn, contrast), $level)};
  }
  --mat-primary: #{map-get($mat-primary, 500)};
  --mat-accent: #{map-get($mat-accent, 500)};
  --mat-warn: #{map-get($mat-warn, 500)};
  --accent: #{$accent};
  --dark-gray: #646464;
  --grayish-brown: #595959;
  --warmer-gray: #757575;
  --warm-gray: #8d8d8d;
  --gray: #aeaeae;
  --silver: #c6c9d0;
  --light-gray: #dadada;
  --lighter-gray: #ececec;
  --not-white: #f2f2f2;
  --warn: #d8315e;
  --primary: #3da7d8;
  --primary-disabled: #3da7d83a;
  --charcoal: #373f4c;
  --facebook-blue: #4267b2;

  --mobile-max: 480px;
  --toolbar-height: 64px;

  --image-placeholder-gradient: linear-gradient(
    -90deg,
    var(--light-gray) 0%,
    var(--not-white) 33%,
    var(--light-gray) 66%
  );
}

@keyframes MovingGradient {
  from {
    background-position: 0% 50%;
  }
  to {
    background-position: 200% 50%;
  }
}


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portalnow-primary: mat-palette($mat-primary);
$portalnow-accent: mat-palette($mat-accent);

// The warn palette is optional (defaults to red).
$portalnow-warn: mat-palette($mat-warn);

// Create the theme object (a Sass map containing all of the palettes).
$portalnow-theme: mat-light-theme($portalnow-primary, $portalnow-accent, $portalnow-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($portalnow-theme);
