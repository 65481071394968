.portal-root {
  .mat-form-field-appearance-outline {
    .mat-form-field-outline-gap {
      width: 0 !important;
    }
    .mat-form-field-outline {
      top: 2.2em;
    }
    .mat-form-field-flex {
      padding: 0 0.75em 0 0;
    }
    .mat-form-field-infix {
      padding: 2.2em 0 1em 0.75em;
    }
    &[float='never'] {
      max-height: var(--height, 40px);
      .mat-form-field-infix {
        padding: 0 0 0 0.75em;
      }
      .mat-form-field-outline {
        top: 0;
      }
      .mat-form-field-flex {
        margin-top: 0;
        height: var(--height, 40px);
      }
      .mat-form-field-wrapper {
        margin: 0;
        padding: 0;
      }
      .mat-select {
        height: calc(var(--height, 40px) - 25px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .mat-input-element {
        height: calc(var(--height, 40px) - 25px);
      }
    }
    .mat-input-element {
      caret-color: var(--mat-outline-color);
      &::placeholder {
        color: var(--mat-label-color);
      }
    }
    .mat-form-field-label {
      transform: translateY(-1.2em) scale(1) !important;
    }
    .mat-form-field-label,
    .mat-select-placeholder,
    .mat-select-arrow {
      color: var(--mat-label-color, --accent);
    }
    &.thick-when-focused .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-width: 2px !important;
        color: var(--mat-outline-color) !important;
      }
    }
    .mat-form-field-outline {
      .mat-form-field-outline-start {
        border-radius: 7px 0 0 7px;
      }
      .mat-form-field-outline-end {
        border-radius: 0 7px 7px 0;
      }
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-width: 1px !important;
      }
    }
    .mat-form-field-outline-thick,
    .mat-form-field-outline {
      color: var(--mat-outline-color, --accent);
      background: var(--background, none);
      border-radius: 7px;
    }
  }

  .mat-snack-bar-container {
    padding: 0;
    border-radius: 0;
  }

  .mat-drawer-container {
    color: var(--accent);
  }

  .mat-flat-button {
    border-radius: 7px;
    line-height: 40px;
    font-size: 13.2;
    &.mat-primary[disabled],
    &.mat-flat-button.mat-accent[disabled],
    &.mat-flat-button.mat-warn[disabled],
    &.mat-flat-button[disabled][disabled] {
      background-color: var(--light-gray);
      color: white;
    }
  }

  .mat-stroked-button {
    border-radius: 7px;
    line-height: 40px;
    font-size: 13.2;
  }

  .mat-card {
    border-radius: 7px;
    color: var(--slate);
    box-shadow: 0 20px 28px -12px rgba(0, 0, 0, 0.3) !important;
    border: solid 1px #b4b4b4;
  }

  .mat-checkbox .mat-checkbox-frame {
    border-width: 1px;
    border-radius: 4px;
  }

  .mat-checkbox .mat-checkbox-background {
    border-radius: 4px;
  }
  .mat-checkbox .mat-checkbox-label {
    font-size: 14px;
    font-weight: 700;
    color: var(--accent);
    padding-left: 6px;
  }
  .subgroup .mat-checkbox .mat-checkbox-label {
    font-weight: normal;
  }
  .mat-checkbox-inner-container {
    height: 16px;
    width: 16px;
  }

  .mat-checkbox .mat-checkbox-checkmark {
    display: none;
  }

  .mat-option-text {
    color: var(--mat-option-color, var(--accent));
    font-weight: 700;
  }
  .mat-option-multiple .mat-option-multiple {
    padding-left: 12px;
  }
  .mat-pseudo-checkbox {
    border-width: 1px;
    border-radius: 4px;
  }
  .mat-pseudo-checkbox-checked::after {
    opacity: 0;
  }

  .mat-select-value-text {
    color: var(--mat-label-color);
  }
  .mat-select-panel {
    border-radius: 7px;
    box-shadow: 0 2px 46px 0 rgba(0, 0, 0, 0.31) !important;
    border: solid 1px var(--gray);
  }
  .nesting-select {
    @media screen and (min-height: 640px) {
      min-height: 480px;
    }
    .mat-optgroup {
      .mat-optgroup-label {
        display: none;
      }
      .mat-option:not(.main) {
        padding-left: 42px;
      }
    }
    .mat-option.checked .mat-pseudo-checkbox {
      background: var(--mat-option-color, var(--primary));
      border-color: transparent;
    }
  }

  .mat-toolbar {
    background-color: white;
    box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.1);
    .mat-button-base.back {
      align-self: center;
    }

    .mat-button-base,
    .mat-form-field {
      margin: 0 8.5px;
      font-size: 14px;
      color: var(--accent);
      &.soft {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &.main-toolbar .mat-button-base {
      margin: 0;
    }

    .mat-form-field {
      min-width: 0;
    }

    // .mat-button-base img {
    //   margin-right: 8px;
    // }

    .mat-form-field {
      font-size: 19px;
    }

    .mat-form-field .mat-input-element {
      font-size: 14px;
      margin: 3px 0;
      display: block;
    }

    .mat-toolbar-row,
    &.mat-toolbar-single-row {
      padding: 0;
      height: var(--toolbar-height);
    }
    &.mat-toolbar-multiple-rows {
      min-height: var(--toolbar-height);
    }
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(1);
    height: 18px;
    width: 18px;
  }

  .mat-radio-outer-circle {
    height: 18px;
    width: 18px;
  }
  .mat-radio-button .mat-radio-ripple {
    left: calc(50% - 21px);
    top: calc(50% - 21px);
  }

  .mat-radio-button {
    margin: 12px 0;
  }

  .mat-radio-label-content {
    padding-left: 14px;
    font-size: 14px;
    font-weight: bold;
    color: var(--accent);
  }

  .ng5-slider {
    .ng5-slider-floor,
    .ng5-slider-ceil {
      display: none;
    }
    .ng5-slider-bar {
      background: var(--primary-disabled);
      height: 2px;
    }
    .ng5-slider-selection {
      background: var(--primary);
    }
    .ng5-slider-pointer {
      width: 14px;
      height: 14px;
      top: -6px;
      outline: none;
    }
    .ng5-slider-pointer:after {
      display: none;
    }
    .ng5-slider-bubble {
      bottom: 14px;
    }
    .ng5-slider-limit {
      font-weight: bold;
      color: var(--primary);
    }
    .ng5-slider-tick {
      display: none;
      // width: 1px;
      // height: 10px;
      // margin-left: 4px;
      // border-radius: 0;
      // background: var(--primary-disabled);
      // top: -1px;
    }
    .ng5-slider-tick.ng5-slider-selected {
      background: var(--primary);
    }
    &.animate {
      .ng5-slider-bubble,
      .ng5-slider-bar-wrapper,
      .ng5-slider-pointer {
        transition: none !important;
      }
    }
  }

  .mat-drawer-content {
    // @media screen and (min-width: 481px) {
    //   height: auto;
    // }
  }

  .dark-bullets {
    --bullet-color: var(--gray);
  }
  .bellow-bullets .swiper-pagination-handle {
    transform: translate3d(0, 11px, 0);
  }
  .custom-bullets .swiper-slide {
    overflow: hidden !important;
  }

  .custom-bullets.dynamic-bullets .swiper-pagination {
    width: 160px !important;
  }

  .custom-bullets .swiper-pagination-bullet {
    background: var(--bullet-color, white);
    opacity: 1;
  }

  .custom-bullets .swiper-pagination-bullet-last:not(.swiper-pagination-bullet-active),
  .custom-bullets .swiper-pagination-bullet-first:not(.swiper-pagination-bullet-active) {
    border: none !important;
  }
  .custom-bullets .swiper-pagination-bullet.swiper-pagination-bullet-last,
  .custom-bullets .swiper-pagination-bullet.swiper-pagination-bullet-first {
    margin: 0 !important;
  }

  .custom-bullets .swiper-pagination-bullet-active {
    background: transparent;
    border: 1px solid var(--bullet-color, white) !important;
    box-sizing: border-box;
  }

  .custom-bullets .swiper-pagination-handle {
    padding: 0 !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 59px;
    height: 60px;
    background-color: rgba(87, 100, 123, 0.23);
    border-radius: 50%;
    background-size: auto;
  }

  .swiper-button-next {
    background-image: url(/es/assets/icons/slider-next.svg);
  }

  .swiper-button-prev {
    background-image: url(/es/assets/icons/slider-prev.svg);
  }

  .gallery.custom-bullets .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  .suggestions-wrapper {
    background-color: white;
    border-radius: 7px;
    box-shadow: 0 20px 28px -12px rgba(0, 0, 0, 0.3);
    .suggestions {
      list-style: none;
      padding: 12px 24px;
      li:not(:last-child) {
        border-bottom: solid 1px var(--light-gray);
      }
    }
    .mapboxgl-ctrl-geocoder--suggestion {
      padding: 8px 0;
      cursor: pointer;
    }

    .mapboxgl-ctrl-geocoder--suggestion-address {
      font-size: 11px;
    }
  }

  #geocoder {
    .geocoder-pin-right {
      display: flex;
      position: absolute;
      right: 36px;
      top: 13px;
      @media screen and (max-width: 960px) {
        right: 12px;
      }
    }

    .suggestions-wrapper {
      display: none;
    }

    .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
      padding: 0px 0px 0px 32px;
      border-radius: 50px;
      box-shadow: none;

      @media screen and (max-width: 960px) {
        padding: 0px 0px 0px 32px;
      }

      input {
        border: solid 1px var(--lighter-gray);
        border-radius: 7px;
        margin-left: -20px;
        outline: none;
        font-size: 12px;
        color: var(--accent);
        height: 39px;
        padding-left: 10px;
        width: 310px;
        @media screen and (max-width: 960px) {
          width: calc(100% - 32px);
          padding-right: 32px;
        }
      }

      .mapboxgl-ctrl-geocoder--icon-search {
        display: none;
      }

      .geocoder-icon-close {
        display: flex;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23636463" fill-rule="evenodd" d="M0 14.36v-.312c.033-.242.182-.408.348-.574 1.706-1.7 3.407-3.403 5.11-5.105.334-.334.334-.42.004-.75C3.748 5.907 2.037 4.19.319 2.482.143 2.307.017 2.123 0 1.873v-.25c.013-.256.14-.445.326-.615.237-.217.465-.445.682-.682.17-.185.36-.313.615-.326h.25c.252.016.435.143.612.32 1.705 1.717 3.418 3.426 5.13 5.136.339.338.418.338.76-.005 1.722-1.723 3.447-3.442 5.163-5.169.157-.158.322-.263.542-.282h.28c.252.021.446.138.616.325.238.261.486.516.75.75.243.213.284.478.272.769a.802.802 0 0 1-.254.56c-1.653 1.643-3.298 3.294-4.948 4.94l-.329.333c-.251.254-.253.377-.004.628.488.49.978.979 1.467 1.468 1.262 1.261 2.518 2.529 3.79 3.78.326.323.313.68.222 1.07-.02.083-.08.143-.138.2-.327.329-.656.654-.98.984-.13.132-.288.17-.46.185-.306.026-.569-.03-.805-.267-1.717-1.733-3.447-3.453-5.17-5.18-.063-.063-.124-.128-.194-.182-.143-.112-.267-.11-.41.003-.054.042-.1.091-.147.14-1.719 1.716-3.444 3.427-5.147 5.159-.294.298-.607.369-.986.316a.563.563 0 0 1-.33-.162L.17 14.815c-.13-.128-.132-.296-.17-.454"/></svg>')
          no-repeat;
        border: none;
        height: 24px;
        width: 24px;
        padding: 4px;
        background-size: 11px;
        background-origin: content-box;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 12px;
        width: 12px;
        height: 13px;
        background: url(/assets/icon/icon-search.svg) no-repeat;
      }

      &.sc-ion-modal-md-h::after {
        background: url(/assets/icon/icon-search.svg) no-repeat;
      }
    }

    .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
      width: 100%;
    }
  }
}
