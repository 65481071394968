.portal-root {
  .only-mobile {
    @media screen and (min-width: 481px) {
      display: none !important;
    }
  }
  .only-gt-mobile {
    @media screen and (max-width: 480px) {
      display: none !important;
    }
  }

  .only-lt-desktop {
    @media screen and (min-width: 760px) {
      display: none !important;
    }
  }

  .only-desktop {
    @media screen and (max-width: 761px) {
      display: none !important;
    }
  }
}
