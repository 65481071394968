body {
  // @extend .bg-gray200;

  margin: 0;
  // font-size: $body-font-size;
  // font-family: $font-family;

}

.column{
  display: flex;
  flex-direction: column;
}

.row{
  display: flex;
  flex-direction: row;
}

.center-items {
  display: flex;
  justify-content: center;
}

.main-container {
  padding: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mat-snack-bar-container.mat-snack-bar-center {
  padding: 0;
}
