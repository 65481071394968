.flex {
  display: flex;
  flex: 1;
}

.flex-mobile {
  display: flex;
  @media screen and (max-width: 480px) {
    flex: 1;
  }
}

.double-flex {
  display: flex;
  flex: 2 !important;
}

.flex-0 {
  display: flex;
  flex: 0;
}

.flex-50 {
  flex: 1 0 50%;
}

.flex-no-shrink {
  display: flex;
  flex-shrink: 0;
}

.flex-shrink {
  display: flex;
  flex-shrink: 1;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-resp-column {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.column-resp-row {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 760px) {
    flex-direction: row;
  }
}

.column-desktop-row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
}

.column-wide-row {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.column {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.center-text {
  text-align: center;
  margin: 0 1rem;
}

.justify {
  text-align: justify;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.auto-center {
  align-self: center;
}

.center-content > * {
  align-self: center;
}

.to-end {
  justify-self: flex-end;
}

.auto-stretch {
  align-self: stretch;
}
